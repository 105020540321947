import { MvnCodeFE } from "./const";

export const STUDY_REPORT_LANG: {
  REPORT_MODIFICATION_REASON_LABEL: MvnCodeFE;
  VIEW_BTN_LIST_LINK: MvnCodeFE;
  VIEW_BTN_DOWNLOAD: MvnCodeFE;
  VIEW_DOWNLOAD_SUCC: MvnCodeFE;
  VIEW_DOWNLOAD_FAIL: MvnCodeFE;
  VIEW_BTN_CREATE_FOLLOW_UP_REPORT: MvnCodeFE;
  VIEW_CREATE_FOLLOW_UP_REPORT_SUCC: MvnCodeFE;
  VIEW_CREATE_FOLLOW_UP_REPORT_FAIL: MvnCodeFE;
  VIEW_BTN_SUBMIT_REPORT: MvnCodeFE;
  VIEW_SUBMIT_CONFIRM_TITLE: MvnCodeFE;
  VIEW_SUBMIT_REPORT_SUCC: MvnCodeFE;
  VIEW_SUBMIT_REPORT_FAIL: MvnCodeFE;
  REPORT_CURRENT_PAGE_DOWNLOAD: MvnCodeFE;
  REPORT_ALL_PAGE_DOWNLOAD: MvnCodeFE;
  CDMS_REPORT_INCLUDED_DATA_DOWNLOAD: MvnCodeFE;
  CDMS_BLANK_REPORT_DOWNLOAD: MvnCodeFE;
  CDMS_ANNOTATED_REPORT_DOWNLOAD: MvnCodeFE;
} = {
  REPORT_MODIFICATION_REASON_LABEL: {
    ko: "수정사유",
    en: "Modification Reason",
  },
  VIEW_BTN_LIST_LINK: {
    ko: "Report List",
    en: "Report List",
  },
  VIEW_BTN_DOWNLOAD: {
    ko: "Download",
    en: "Download",
  },
  VIEW_DOWNLOAD_SUCC: {
    ko: "보고서 다운로드가 시작되었습니다.",
    en: "Report download has been started.",
  },
  VIEW_DOWNLOAD_FAIL: {
    ko: "보고서 다운로드 요청에 실패하였습니다. ({message})",
    en: "Report download request has been failed. ({message})",
  },
  VIEW_BTN_CREATE_FOLLOW_UP_REPORT: {
    ko: "Follow-up Report 생성",
    en: "Create Follow-up Report",
  },
  VIEW_CREATE_FOLLOW_UP_REPORT_SUCC: {
    ko: "추적보고서 생성에 성공하였습니다.",
    en: "Follow-up report has been created successfully.",
  },
  VIEW_CREATE_FOLLOW_UP_REPORT_FAIL: {
    ko: "추적보고서 생성에 실패하였습니다. ({message})",
    en: "Follow-up report creation has been failed. ({message})",
  },
  VIEW_BTN_SUBMIT_REPORT: {
    ko: "Report Document 제출",
    en: "Submit Report Document",
  },
  VIEW_SUBMIT_CONFIRM_TITLE: {
    ko: "Report 제출을 위해 비밀번호를 입력해 주세요.",
    en: "Please enter your password to submit the report.",
  },
  VIEW_SUBMIT_REPORT_SUCC: {
    ko: "보고서 제출에 성공하였습니다.",
    en: "Report has been submitted successfully.",
  },
  VIEW_SUBMIT_REPORT_FAIL: {
    ko: "보고서 제출에 실패하였습니다. ({message})",
    en: "Report submission has been failed. ({message})",
  },
  REPORT_CURRENT_PAGE_DOWNLOAD: {
    ko: "현재 페이지 다운로드",
    en: "Download current page",
  },
  REPORT_ALL_PAGE_DOWNLOAD: {
    ko: "전체 레포트 다운로드",
    en: "Download full report",
  },
  CDMS_REPORT_INCLUDED_DATA_DOWNLOAD: {
    ko: "Data Report",
    en: "Data Report",
  },
  CDMS_BLANK_REPORT_DOWNLOAD: {
    ko: "Blank Report",
    en: "Blank Report",
  },
  CDMS_ANNOTATED_REPORT_DOWNLOAD: {
    ko: "Annotated 포함",
    en: "Include Annotated",
  },
};
