import { MvnCodeFE } from "./const";

export const STUDY_CUSTOM_DATA_CRF_LANG: {
  CUSTOM_DATA_CRF_NOTICE: MvnCodeFE;
  CUSTOM_DATA_CRF_SUBJECT_TITLE: MvnCodeFE;
  CUSTOM_DATA_CRF_FILE_TITLE: MvnCodeFE;
  CUSTOM_DATA_CRF_FILE_REQUEST_SUCC: MvnCodeFE;
  CUSTOM_DATA_CRF_FILE_REQUEST_FAIL: MvnCodeFE;
} = {
  CUSTOM_DATA_CRF_NOTICE: {
    ko: "Request된 파일의 다운로드 유효기간은 1일이며 유효기간 만료 후에는 다시 Request 해야 합니다.",
    en: "The download validity period for the requested file is 1 day. After the validity period expires, you will need to request it again.",
  },
  CUSTOM_DATA_CRF_SUBJECT_TITLE: {
    ko: "Subject",
    en: "Subject",
  },
  CUSTOM_DATA_CRF_FILE_TITLE: {
    ko: "Data Combination File",
    en: "Data Combination File",
  },
  CUSTOM_DATA_CRF_FILE_REQUEST_SUCC: {
    ko: "Data Combination File 생성이 요청되었습니다.",
    en: "The creation of the Data Combination File has been requested.",
  },
  CUSTOM_DATA_CRF_FILE_REQUEST_FAIL: {
    ko: "Data Combination File 생성 요청에 실패하였습니다. ({message})",
    en: "The request to create the Data Combination File failed. ({message})",
  },
};
