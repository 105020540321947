import { MvnCodeFE } from "./const";

export const STUDY_DATA_EXPORT_LANG: {
  STUDY_DATA_EXPORT_SPEC_NOT_EXIST: MvnCodeFE;
  STUDY_DATA_EXPORT_REQUEST_SUCC: MvnCodeFE;
  STUDY_DATA_EXPORT_REQUEST_FAIL: MvnCodeFE;
  STUDY_DATA_EXPORT_DOWNLOAD_REQUEST_SUCC: MvnCodeFE;
  STUDY_DATA_EXPORT_DOWNLOAD_REQUEST_FAIL: MvnCodeFE;
} = {
  STUDY_DATA_EXPORT_SPEC_NOT_EXIST: {
    ko: "정의된 Data Export 정보가 존재하지 않습니다.",
    en: "The information of defined Data Export does not exist.",
  },
  STUDY_DATA_EXPORT_REQUEST_SUCC: {
    ko: "Data Export 요청이 완료되었습니다.",
    en: "Data Export request has been completed.",
  },
  STUDY_DATA_EXPORT_REQUEST_FAIL: {
    ko: "Data Export 요청이 실패했습니다. ({message})",
    en: "Data Export request has been failed. ({message})",
  },
  STUDY_DATA_EXPORT_DOWNLOAD_REQUEST_SUCC: {
    ko: "Data Export Download 요청이 완료되었습니다.",
    en: "Data Export Download request has been completed.",
  },
  STUDY_DATA_EXPORT_DOWNLOAD_REQUEST_FAIL: {
    ko: "Data Export Download 요청이 실패했습니다. ({message})",
    en: "Data Export Download request has been failed. ({message})",
  },
};
