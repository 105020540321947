import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const CDMS_STUDY_CAPTURE_STATUS_CODE: {
  CDMS_STUDY_CAPTURE_STATUS_INVALID_STATUS_REQUIREMENT: MvnCodeBE;
} = {
  CDMS_STUDY_CAPTURE_STATUS_INVALID_STATUS_REQUIREMENT: {
    status: 400,
    ko: "해당 {category}에 대한 {status}을(를) 수행할 수 없습니다.",
    en: "The {status} action cannot be performed for the given {category}.",
  },
};

MvnCodeInitiate(CDMS_STUDY_CAPTURE_STATUS_CODE);
